import { identity, values } from 'lodash-es';
import { Observable, combineLatest, defer, take } from 'rxjs';
import {
    filter,
    first,
    map,
    startWith,
    switchMap,
    takeUntil
} from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { Dealer } from '@mhp/aml-shared/data-proxy/dealer.interface';
import {
    SalesforceRequestConfigurationViaEmailInterface,
    SalesforceService
} from '@mhp/aml-ui-shared-services';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { UiBaseComponent, UiNotificationService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    I18nService,
    L10nService,
    RecaptchaError,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { getTranslatedTitlesList } from '../../../common/forms/form-constants';
import { EMAIL_REGEX } from '../../../common/validation/validation.helpers';
import { SalesforceContextService } from '../../../configuration/salesforce/salesforce-context.service';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { SALESFORCE_EVENT_CONFIGURATOR_EMAIL_BROCHURE } from '../../../salesforce-events/salesforce-events';
import { PricingService } from '../../pricing/pricing.service';

@Component({
    selector: 'mhp-request-pdf-form',
    templateUrl: './request-pdf-form.component.html',
    styleUrls: ['./request-pdf-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestPdfFormComponent extends UiBaseComponent implements OnInit {
    @Input()
    formId: string;

    @Input()
    fixedCountry?: string;

    @Input()
    requiresConsent = true;

    leadFormGroup: UntypedFormGroup;

    readonly availableTitles = getTranslatedTitlesList();

    readonly compareDealers = (dealerInfo1?: Dealer, dealerInfo2?: Dealer) =>
        dealerInfo1?.dealerCode === dealerInfo2?.dealerCode;

    constructor(
        private readonly l10nService: L10nService,
        private readonly i18nService: I18nService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly labelHelperService: LabelHelperService,
        private readonly salesforceService: SalesforceService,
        private readonly salesforceContextService: SalesforceContextService,
        private readonly notificationService: UiNotificationService,
        private readonly pricingService: PricingService
    ) {
        super();
    }

    ngOnInit() {
        this.leadFormGroup = new UntypedFormGroup({
            country: new UntypedFormControl(undefined, Validators.required),
            title: new UntypedFormControl(undefined),
            firstName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            customerEmail: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40),
                Validators.pattern(EMAIL_REGEX)
            ]),
            customerPhone: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(15),
                Validators.pattern('[- +()0-9]+')
            ]),
            includePdfPricing: new UntypedFormControl(false)
        });

        if (this.requiresConsent) {
            this.leadFormGroup.addControl(
                // contact-options
                'contactOptions',
                new UntypedFormGroup({
                    contactViaEmail: new UntypedFormControl(undefined),
                    contactViaTelephone: new UntypedFormControl(undefined),
                    contactViaSms: new UntypedFormControl(undefined),
                    contactViaNewsletter: new UntypedFormControl(undefined)
                })
            );
        }

        if (!this.fixedCountry) {
            this.l10nService
                .getActiveCountry$()
                .pipe(
                    first(),
                    // when fixedCountry is set later-on, do not override with country
                    takeUntil(
                        this.observeProperty<RequestPdfFormComponent, 'string'>(
                            'fixedCountry',
                            true
                        ).pipe(filter(identity))
                    ),
                    this.takeUntilDestroy()
                )
                .subscribe((country) =>
                    this.leadFormGroup.get('country')?.setValue(country)
                );
        }
    }

    @MemoizeObservable()
    getModelLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    getActiveLang$(): Observable<string> {
        return this.i18nService.getActiveLang$();
    }

    getServerCallInProgress$() {
        return this.serverCallInProgress$;
    }

    @MemoizeObservable()
    canSubmit$() {
        return this.serverCallInProgress$.pipe(
            map((formSubmitInProgress) => !formSubmitInProgress),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getGdprMarkdown$(number: number) {
        return this.salesforceContextService.getGdprMarkdown$(number);
    }

    intentSubmit() {
        if (!this.leadFormGroup.valid) {
            values(this.leadFormGroup.controls).forEach((control) =>
                control.markAllAsTouched()
            );
            return;
        }

        this.submitToBackend();
    }

    @MemoizeObservable()
    isIncludePricingAvailable$() {
        return this.pricingService.isPdfPricingAvailable$();
    }

    private submitToBackend() {
        const retryableSubmission$ = defer(() => {
            const pricingTypeToBeUsed$ =
                this.salesforceContextService.getPricingTypeToBeUsed$(
                    this.leadFormGroup.controls.includePdfPricing?.valueChanges.pipe(
                        startWith(
                            this.leadFormGroup.controls.includePdfPricing?.value
                        )
                    )
                );

            return combineLatest([
                this.salesforceContextService.getSalesforceContextData$({
                    includeDealerConfigurationUrl: true
                }),
                pricingTypeToBeUsed$
            ]).pipe(
                first(),
                switchMap(([salesforceContextData, pricingTypeToBeUsed]) => {
                    if (!salesforceContextData.productId) {
                        throw new IllegalStateError('Missing productId');
                    }
                    if (!salesforceContextData.dealerConfigurationLink) {
                        throw new IllegalStateError(
                            'Missing configuration URL'
                        );
                    }
                    if (!salesforceContextData.configurationEncoded) {
                        throw new IllegalStateError(
                            'Missing encoded configuration'
                        );
                    }

                    if (!salesforceContextData.transmission) {
                        throw new IllegalStateError(
                            'Missing transmission value'
                        );
                    }

                    const formValue = this.leadFormGroup.value;

                    const payload: SalesforceRequestConfigurationViaEmailInterface =
                        {
                            title: formValue.title,
                            firstName: formValue.firstName,
                            lastName: formValue.lastName,
                            customerEmail: formValue.customerEmail,
                            customerPhone: formValue.customerPhone,
                            countryIsoCode: formValue.country,
                            languagePreference: <any>(
                                salesforceContextData.languagePreference
                            ),
                            consentDateTime: new Date().getTime(),
                            userOptInEmail:
                                !!formValue.contactOptions?.contactViaEmail,
                            userOptInSMS:
                                !!formValue.contactOptions?.contactViaSms,
                            userOptInPhone:
                                !!formValue.contactOptions?.contactViaTelephone,
                            userOptInNewsletter:
                                !!formValue.contactOptions
                                    ?.contactViaNewsletter,
                            configurationLink:
                                salesforceContextData.dealerConfigurationLink,
                            configurationEncoded:
                                salesforceContextData.configurationEncoded,
                            pdfPricing: pricingTypeToBeUsed,
                            derivate: salesforceContextData.productId,
                            // color options
                            exteriorColor: salesforceContextData?.exteriorColor,
                            interiorColorPrimary:
                                salesforceContextData?.interiorColor1,
                            interiorColorSecondary:
                                salesforceContextData?.interiorColor2,
                            transmission: salesforceContextData.transmission,
                            // UTM data
                            referralWebForm:
                                salesforceContextData.utmData?.getAsUrlEncodedString(
                                    255
                                ),
                            emailRequest: true
                        };

                    return this.salesforceService.requestPdfViaMail$(payload);
                })
            );
        });

        gtmGA4Track('email_brochure_submit');

        this.notificationService.showNotification(
            translate('SUMBIT_TO_DEALER.EMAIL.SUBMIT_START')
        );

        this.handleServerCallInProgress(
            retryableSubmission$.pipe(
                this.errorHandlerService.applyRetry({
                    messageProviderOnFinalError: (error) => {
                        // track error
                        gtmGA4Track('email_brochure_failed');

                        if (error instanceof RecaptchaError) {
                            return translate(
                                'COMMON.ERROR.RECAPTCHA_CHECK_FAILED'
                            );
                        }
                        console.error('Failed submitting to dealer', error);
                        return translate(
                            'SUMBIT_TO_DEALER.EMAIL.ERRORS.SUBMIT_FAILED'
                        );
                    }
                })
            )
        ).subscribe((payload) => {
            // track success
            gtmGA4Track('email_brochure_success');

            this.salesforceContextService
                .getSalesforceContextData$({
                    includeDealerConfigurationUrl: true
                })
                .pipe(take(1))
                .subscribe((contextData) => {
                    const { modelId, productId } = contextData;

                    if (!modelId || !productId) {
                        return;
                    }

                    // SF Data Cloud: Configurator Email Personalised Brochure
                    sfEvent({
                        interaction: {
                            ...SALESFORCE_EVENT_CONFIGURATOR_EMAIL_BROCHURE,
                            modelCode: modelId,
                            variantCode: productId,
                            configUrl: contextData.dealerConfigurationLink ?? ''
                        },
                        user: {
                            attributes: {
                                firstName: payload.firstName,
                                lastName: payload.lastName,
                                email: payload.customerEmail,
                                phone: payload.customerPhone,
                                eventType: 'identity',
                                isAnonymous: 0
                            }
                        }
                    });

                    // SF Data Cloud: contactPointEmail
                    sfEvent({
                        user: {
                            attributes: {
                                email: payload.customerEmail,
                                eventType: 'contactPointEmail'
                            }
                        }
                    });

                    // SF Data Cloud: contactPointPhone
                    sfEvent({
                        user: {
                            attributes: {
                                phoneNumber: payload.customerPhone,
                                eventType: 'contactPointPhone'
                            }
                        }
                    });
                });

            this.closeNotificationOnDestroy(
                this.notificationService.showAutohideNotification(
                    translate('SUMBIT_TO_DEALER.EMAIL.SUBMIT_SUCCESS')
                )
            );
        });
    }
}
